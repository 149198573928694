import "./App.css"
import React, { useCallback } from "react"
import { Button, Card, Form } from "antd"
import {
    AarcSwitchWidgetProvider,
    FKConfig,
    TransactionErrorData,
    TransactionSuccessData,
    useModal,
    useTheme,
} from "@aarc-xyz/fund-kit-widget"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { AarcEthWalletConnector } from "@aarc-xyz/eth-connector"
import "@aarc-xyz/eth-connector/styles.css"

const queryClient = new QueryClient()

export function OpenModal() {
    const { theme, setTheme } = useTheme()
    const [contractPayload, setContractPayload] = React.useState("")

    const { setOpenModal, client, openModal } = useModal()
    const handleSubmit = useCallback(() => {
        console.log("Open Modal", openModal)
        setOpenModal(true)
    }, [openModal, setOpenModal])

    const generateContractPayload = async () => {
        setContractPayload(
            "0xc6c3bbe6000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48000000000000000000000000eda8dec60b6c2055b61939dda41e9173bab372b200000000000000000000000000000000000000000000000000000000000f4240"
        )
        client?.updateDestinationContract({
            contractAddress: "0x94De497a5E88Da7bc522a8203100f99Dd6e6171e",
            contractGasLimit: "2000000",
            contractPayload:
                "0xc6c3bbe6000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48000000000000000000000000eda8dec60b6c2055b61939dda41e9173bab372b200000000000000000000000000000000000000000000000000000000000f4240",
            contractName: "Aave",
        })
        return
    }

    return (
        <div className="flex flex-col gap-5 items-center justify-center">
            <Card title="Transfer Balance" style={{ width: "500px" }}>
                <div className="flex flex-col gap-5 items-center justify-center">
                    <Form>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    client?.updateDestinationContract(undefined)
                                    handleSubmit()
                                }}
                                type="primary"
                                shape="round"
                                htmlType="submit"
                            >
                                Deposit
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                onClick={async () => {
                                    try {
                                        await generateContractPayload()
                                        handleSubmit()
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}
                                type="primary"
                                shape="round"
                                htmlType="submit"
                            >
                                Checkout
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>
        </div>
    )
}

function App() {
    const config = {
        appName: "Dapp Name",
        module: {
            exchange: {
                enabled: true,
                env: "prod",
            },
            onRamp: {
                enabled: true,
                onRampConfig: {
                    mode: "deposit",
                    customerId: "323232323",
                    exchangeScreenTitle: "Deposit funds in your wallet",
                    networks: ["ethereum", "polygon"],
                    defaultNetwork: "polygon",
                },
            },
            bridgeAndSwap: {
                enabled: true,
                fetchOnlyDestinationBalance: false,
                routeType: "Value",
            },
        },
        destination: {
            walletAddress: "0xeDa8Dec60B6C2055B61939dDA41E9173Bab372b2",
        },
        appearance: {
            themeColor: "#2D2D2D",
            textColor: "#2D2D2D",
            backgroundColor: "#FFF",
            highlightColor: "#F0F0F0",
            dark: {
                themeColor: "#FFF",
                textColor: "#FFF",
                backgroundColor: "#2D2D2D",
                highlightColor: "#2D2D2D",
            },
        },

        apiKeys: {
            aarcSDK: process.env.REACT_APP_PROD_AARC_API_KEY, // Prod
        },
        events: {
            onTransactionSuccess: (data) => {
                console.log("onTransactionSuccess", data)
            },
            onTransactionError: (data) => {
                console.log("onTransactionError", data)
            },
            onWidgetClose: () => {
                console.log("onWidgetClose")
            },
            onWidgetOpen: () => {
                console.log("onWidgetOpen")
            },
        },
    }

    return (
        <div
            className="App flex-col"
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <QueryClientProvider client={queryClient}>
                <AarcSwitchWidgetProvider config={config}>
                    <AarcEthWalletConnector>
                        <div className="aarc_main_container">
                            {/* <CustomButton /> */}
                            <OpenModal />
                        </div>
                    </AarcEthWalletConnector>
                </AarcSwitchWidgetProvider>
            </QueryClientProvider>
        </div>
    )
}

export default App
